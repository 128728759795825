/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import { COMPANY_IMAGES } from 'components/LandingPages/common/consts';
import { SECTIONS, COMPONENTS, CATEGORIES, GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { getStaticMediaPath } from 'utils/media';
import { useQuery } from 'utils/react-query';
import useAppContext from 'utils/hooks/useAppContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import ImpressionTracker from 'utils/components/impressionTracker';
import { TRACK_EVENTS, CUSTOMER_LOGOS } from 'utils/tracking';

import { TP, BASE_PAGE_ROUTES, URL_STATIC_PATHS, ENTITY_TYPE, CUSTOMER_LOGO_SCROLL_ID } from 'constants/index';

import { useTranslation } from 'src/i18n';

import queries from 'containers/Organizations/queries';

import classes from './CustomerLogos.module.scss';

const ASPECT_RATIO = 130 / 77;

const ViewAllBtn = ({ linkProps, handleOnClick, scrollSectionIntoView, onClickViewAll }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <LinkButton
      variant="text"
      {...(linkProps
        ? {
            ...linkProps,
            isLink: true,
            onClick: e => {
              handleOnClick(e, 'all');
              if (onClickViewAll) {
                onClickViewAll();
              }
            },
          }
        : {
            onClick: () => {
              scrollSectionIntoView(CUSTOMER_LOGO_SCROLL_ID);
              if (onClickViewAll) {
                onClickViewAll();
              }
            },
          })}
      disableHover
      disableUnderline
      trackingData={{
        section: SECTIONS.ORGANIZATION_LOGOS,
        component: COMPONENTS.VIEW_MORE_CTA,
      }}
    >
      <Typography size={11} color="secondary" className={classes.linkWrapper__text}>
        {t(`${TP}.VIEW_ALL`)}
      </Typography>
    </LinkButton>
  );
};

const CustomerLogos = ({
  className,
  count = 12,
  showAll = false,
  allowShowAll = true,
  navigateToSamePage = false,
  showTitle = false,
  rows = 1,
  rowHeight = 48,
  gap = 0,
  columnGap = 0,
  rowGap = 0,
  hasLogosViewAll = false,
  onClickViewAll = () => {},
  noFollow = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const { location, hasFingerprintFailed } = useAppContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate, scrollSectionIntoView, baseRoute } = usePageContext();
  const { gridWidth, height } = useMemo(() => {
    const imageWidth = ASPECT_RATIO * rowHeight + 20;

    return {
      gridWidth: imageWidth,
      height: Math.ceil(rowHeight),
    };
  }, [rowHeight]);

  const enableSuggestedOrgFetch = !showAll && !hasFingerprintFailed && location?.isReady;

  const { data: suggestedOrganisations } = useQuery(
    queries.getCustomerLogos({
      limit: count,
      filters: {
        city_id: location?.city?.id,
        country_id: location?.country?.id,
        region_id: location?.region?.id,
      },
      queryConfig: {
        enabled: enableSuggestedOrgFetch,
        select: response => response?.data,
      },
    }),
  );

  const containerRef = useRef(null);
  const [numImagesToShow, setNumImagesToShow] = useState(2);

  const [loadedImages, setLoadedImages] = useState([]);

  const companies = useMemo(() => {
    const allCompanies = enableSuggestedOrgFetch ? suggestedOrganisations : [...COMPANY_IMAGES];

    const sortedCompanies = allCompanies?.sort(() => (Math.random() > 0.5 ? 1 : -1)) || [];

    if (showAll) {
      return sortedCompanies;
    }

    return sortedCompanies.slice(0, count);
  }, [count, enableSuggestedOrgFetch, suggestedOrganisations, showAll]);

  const loadMoreImages = useCallback(() => {
    const loadedImagesCount = loadedImages.length || 0;

    if (loadedImagesCount >= companies.length) {
      return;
    }

    const nextBatch = companies.slice(loadedImages.length, loadedImages.length + 5);
    setLoadedImages(prev => [...prev, ...nextBatch]);
  }, [companies, loadedImages]);

  const observerRef = useRef(null);

  useEffect(() => {
    if (hasLogosViewAll) {
      const element = observerRef?.current;
      let observer;

      if (element) {
        observer = new IntersectionObserver(
          entries => {
            if (entries[0].isIntersecting) {
              loadMoreImages();
            }
          },
          { threshold: 0.1 },
        );
        observer.observe(element);
      }

      return () => {
        if (observer) {
          observer.unobserve(element);
        }
      };
    }
  }, [companies, hasLogosViewAll, loadedImages.length]);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.clientWidth;
      const newNumImagesToShow = Math.floor(containerWidth / gridWidth);
      setNumImagesToShow(prev => (prev !== newNumImagesToShow ? newNumImagesToShow : prev));
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [gridWidth]);

  const renderCompanyLogos = useMemo(() => {
    if (hasLogosViewAll) {
      return loadedImages;
    }

    if (showAll) {
      return companies;
    }

    return companies?.slice(0, numImagesToShow * rows);
  }, [companies, hasLogosViewAll, loadedImages, numImagesToShow, rows, showAll]);

  const linkProps = useMemo(() => {
    if (navigateToSamePage) {
      return null;
    }

    return navigate.getLinkProps({
      baseRoute: BASE_PAGE_ROUTES.PRO,
      path: URL_STATIC_PATHS.COMPANIES,
      scrollTo: CUSTOMER_LOGO_SCROLL_ID,
    });
  }, [navigate, navigateToSamePage]);

  const handleOnClick = useCallback(
    (e, type) => {
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          id: type === 'all' ? CUSTOMER_LOGOS.VIEW_ALL_LOGOS_CLICK : CUSTOMER_LOGOS.SINGLE_LOGO_CLICK,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );

      navigate.scrollTo({ behavior: 'auto' });
    },
    [navigate, track],
  );

  const onCastingPage = baseRoute === BASE_PAGE_ROUTES.CASTING;

  return (
    <div
      data-scroll-id={showAll ? CUSTOMER_LOGO_SCROLL_ID : null}
      data-scroll-offset={onCastingPage ? 70 : 130}
      className={classnames(classes.root, {
        [className]: !!className,
        [classes.showAll]: showAll,
        [classes.hideShowAll]: !allowShowAll && showTitle,
        [classes.hideTitle]: allowShowAll && !showTitle,
        [classes.showOnlyLogos]: !allowShowAll && !showTitle,
      })}
    >
      {showTitle && (
        <>
          {showAll ? (
            <Typography
              variant="h2"
              size={isDesktop ? '32' : '18'}
              weight={isDesktop ? 'regular' : 'bold'}
              align="center"
              secondaryFont
            >
              {t(`${TP}.LP_COMPANIES_BLOCK_TITLE`)}
            </Typography>
          ) : (
            <Typography variant="p" className={classes.title} size={12} italic>
              {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}
            </Typography>
          )}
        </>
      )}
      <div
        className={classnames(classes.logoList, {
          [classes.inlineLogos]: !!hasLogosViewAll,
          [classes.multiRows]: rows > 1,
        })}
        {...(!showAll && {
          style: {
            height: `${rows * height + (gap || columnGap + rowGap) * 2}px`,
            ...(gap ? { gap } : {}),
            ...(columnGap ? { columnGap } : {}),
            ...(rowGap ? { rowGap } : {}),
          },
        })}
        ref={containerRef}
      >
        {renderCompanyLogos?.map(company => {
          const trackingData = {
            section: SECTIONS.ORGANIZATION_LOGOS,
            entityId: company.id,
            entityName: company.name,
            entityType: ENTITY_TYPE.ORGANIZATION,
            category: CATEGORIES.RECOMMENDED,
          };

          return (
            <LinkButton
              key={company.id}
              variant="native"
              {...navigate.getLinkProps({
                entity: company,
                entityType: ENTITY_TYPE.ORGANIZATION,
                ...(noFollow && { rel: 'nofollow' }),
              })}
              styles={{ root: classes.logoList_item }}
              {...(!showAll && { inlineStyle: { flex: `0 0 ${gridWidth}px`, height } })}
              title={company.name}
              isLink
              onClick={handleOnClick}
              trackingData={trackingData}
            >
              <ImpressionTracker data={trackingData}>
                <Image
                  src={
                    enableSuggestedOrgFetch
                      ? company.logo
                      : getStaticMediaPath(`images/landing-pages/companies-logos/${company.logo}`)
                  }
                  alt={company.name}
                  title={company.name}
                  disableCloudinary={!enableSuggestedOrgFetch}
                  transformations={TRANSFORMATIONS.CUSTOMER_LOGO}
                  useIntersectionObserver
                  disableNextImage
                />
              </ImpressionTracker>
            </LinkButton>
          );
        })}
        {hasLogosViewAll && <div ref={observerRef} style={{ width: '1px', height: '1px ' }} />}
        {hasLogosViewAll && (
          <span className={classes.inLineViewAll}>
            <ViewAllBtn
              linkProps={linkProps}
              handleOnClick={handleOnClick}
              onClickViewAll={onClickViewAll}
              scrollSectionIntoView={scrollSectionIntoView}
            />
          </span>
        )}
      </div>
      <>
        {!showAll && allowShowAll && (
          <div className={classes.linkWrapper}>
            <ViewAllBtn
              linkProps={linkProps}
              handleOnClick={handleOnClick}
              onClickViewAll={onClickViewAll}
              scrollSectionIntoView={scrollSectionIntoView}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default CustomerLogos;
