import React from 'react';
import classnames from 'classnames';
import { InView } from 'react-intersection-observer';
import useTracking from 'components/Globals/Analytics';

const ImpressionTracker = props => {
  const track = useTracking();
  const { data, onClick, inViewCallback, threshold = 0.5, skipEntityCheck = false } = props;
  const entityType = data?.entityType;
  return (
    <InView
      as={props.as || 'div'}
      triggerOnce="true"
      threshold={threshold}
      onChange={inView => {
        if (inView) {
          if ((entityType && data.entityId !== -1) || (data && skipEntityCheck)) {
            track.impression(data);
          }

          if (inViewCallback) {
            inViewCallback(data);
          }
        }
      }}
      className={classnames('ob-impression', {
        [`ob-${entityType}`]: !!entityType,
        [`ob-${data?.entityId}`]: !!data?.entityId,
        [`ob-section`]: !!data?.section,
        [props.className]: !!props.className,
      })}
      onClick={(...args) => {
        if (onClick) {
          onClick(...args);
        }
      }}
      {...(props?.itemScope && { itemScope: true })}
      {...(props?.itemType && { itemType: props.itemType })}
    >
      {props.children}
    </InView>
  );
};

ImpressionTracker.propTypes = {};

export default ImpressionTracker;
